/**
 * MIXIN FUNCTIONS: section, form, and input element breakers for Desking
 */
@mixin section-breaker($margin, $thickness, $color) {
  margin: 0;
  box-sizing: border-box;
  padding: $margin 0;
  height: 0;
  border: 0;
  position: relative;

  &:after {
    display: inline-block;
    content: '';
    position: absolute;
    left: 50%; // Positioning
    height: $thickness;
    width: calc(100% + 8px);
    bottom: $thickness;
    background-color: $color;
    -webkit-transform: translateX(-50%); // Positioning
    transform: translateX(-50%); // Positioning
  }
}

hr.standard-section-breaker {
  @include section-breaker(3px, 2px, #ccc);
}

/*
 * Overrides
*/
.form-control {
  transition: none !important;
}

/**
 * Custom Misc CSS
 */
.cursor-default {
  cursor: default;
}

.color-grey-4 {
  color: #333;
}

.w-65 {
  width: 65% !important;
}

.select-field-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 2px;
  margin-bottom: 0;

  div {
    white-space: nowrap;
    box-sizing: border-box;
    padding: 0 10px 0 2px;
    flex-grow: 2;
    font-size: 13pt;
    color: #333;
    pointer-events: none;
  }

  select {
    font-size: 12pt;
    height: 26px;
    line-height: 26px;
    color: #027aff;
    border-color: #999 !important;
    flex-grow: 0;
    border-radius: 4px;
    padding: 0;

    &:hover {
      cursor: pointer;
      border-color: #027aff !important;
    }
  }
}

.section-header {
  cursor: default;
  box-sizing: border-box;
  min-height: 32px;
  padding: 7px 7px 7px 5px !important;
  margin: 4px 2px !important;
  background-color: #c2cdbb !important;
  color: #30451e;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
}

.section-header-with-button {
  cursor: pointer;
  box-sizing: border-box;
  min-height: 32px;
  padding: 1px 6px 0 5px !important;
  margin: 4px 2px !important;
  background-color: #c2cdbb !important;
  color: #30451e;
  font-weight: bold;
  border-radius: 4px 4px 0 0;

  span {
    flex-grow: 2;
  }
}

.sub-section-header {
  cursor: default;
  box-sizing: border-box;
  min-height: 32px;
  padding: 7px 7px 7px 5px !important;
  margin: 4px 2px !important;
  background-color: #cecece !important;
  color: #30451e;
  font-weight: bold;
  border-radius: 4px 4px 0 0;
  margin-top: 7px !important;
  display: flex;
  align-items: center;
  gap: 4px;

  > span {
    flex-grow: 2;
  }
}

.primary-section-header-selected {
  .primary-section-header-block {
    font-size: 18px !important;
    background-color: #496f29 !important;
    border-bottom: 1px solid #496f29;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5) !important;
    margin: 0 -5px !important;
    padding: 6px 6px 7px 6px !important;
    cursor: default !important;
  }
}

.primary-section-header {
  .primary-section-header-block {
    font-size: 18px !important;
    background-color: #6A6A6AFF !important;
    border-bottom: 1px solid #1d1d1d;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5) !important;
    margin: 0 -5px !important;
    padding: 6px 6px 7px 6px !important;
    cursor: pointer !important;
  }
}
