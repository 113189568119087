.warranty-ui {
  &-gray-1 {
    background: whitesmoke;
  }

  &-gray-2 {
    background: lightgray;
  }

  &-gray-3 {
    background: darkgray;
  }

  &-green {
    background: #EFF5CB;
  }

  &-blue {
    background: #84CDDB;
  }

  &-red-gradient {
    background: linear-gradient(to right, #FFF, #FBA);
  }

  &-light-stripe {
    background: repeating-linear-gradient(
        -45deg,
        whitesmoke,
        whitesmoke 10px,
        #FFF 10px,
        #FFF 20px
    );
  }
}
