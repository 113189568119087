/* APP COLOR PALETTE */
$app-primary: rgb(65, 95, 45);
$app-primary-accent: #75d62e;
$app-secondary: rgb(94, 151, 50);
$app-success: rgb(15, 117, 255);
$app-neutral: #6c757d;
$app-dark-gray: #444444;
$app-lt-gray: #E8E8E8;
$app-gray: #C9C9C9;
$app-disabled-gray: #E9ECEF;
$app-danger: #dc3545;
$app-info: #17a2b8;
$app-light: #f8f9fa;
$app-white: #ffffff;
$app-dark: #212529;
$app-blue: #027aff;
$app-lt-blue: #afd4ff;
