@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

html {
  font-size: 0.8rem;
}

.material-icons {
  font-size: 1.25rem;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1rem;
  }
}
