@import "./variables.scss";

.app-text {
  &-primary {
    color: $app-primary !important;

    &-accent {
      color: $app-primary-accent !important;
    }
  }

  &-secondary {
    color: $app-secondary !important;
  }

  &-success {
    color: $app-success !important;
  }

  &-danger {
    color: $app-danger !important;
  }

  &-neutral {
    color: $app-neutral !important;
  }

  &-info {
    color: $app-info !important;
  }

  &-light {
    color: $app-light !important;
  }

  &-dark {
    color: $app-dark !important;
  }

  &-white {
    color: $app-white !important;
  }

  &-larger {
    font-size: larger;
  }

  &-small {
    font-size: 0.8rem;
  }

  &-bold {
    font-weight: bold;
  }

  &-blue {
    color: $blue !important;
  }
}
