@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

.app-page-container {
  padding: 1rem;
}

.app-content-container {
  max-width: 1400px;
  margin: auto;
}

.footer-menu {
  opacity: 1;
  position: fixed;
  left: 50%;
  bottom: 30px;
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0 0 10px 2px #3332;
  background-color: #fff;
  transform: translate(-50%, 0px);
  transition: transform 0.25s linear, opacity 0.25s linear;
  z-index: 100;

  &_hidden {
    opacity: 0;
    transform: translate(-50%, 80px);
  }
}

.sticky-footer {
  position: fixed !important;
  width: 100%;
  bottom: 0;
}

@include media-breakpoint-up(md) {
  .app-page-container {
    padding: 1.5rem;
  }
}

.app-inline-form-layout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ccc;
}

.new-accessory {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  gap: 3px;
  box-sizing: border-box;
  padding: 3px 0;

  .button-bar {
    display: flex;
    gap: 3px;
    padding-top: 3px;

    button {
      font-size: 0.8rem;
    }
  }
}

.desking-column-sub-label {
  width: 100%;
  font-weight: bold;
  font-size: 16px !important;
  color: #333;

  &.underlined {
    padding: 4px 4px 2px 4px;
    border-bottom: 1px solid #999;
  }
}
