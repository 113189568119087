@import "./variables.scss";

.app-bg {
  &-primary {
    background-color: $app-primary !important;

    &-accent {
      background-color: $app-primary-accent !important;
    }
  }

  &-secondary {
    background-color: $app-secondary !important;
  }

  &-success {
    background-color: $app-success !important;
  }

  &-danger {
    background-color: $app-danger !important;
  }

  &-neutral {
    background-color: $app-neutral !important;
  }

  &-info {
    background-color: $app-info !important;
  }

  &-light {
    background-color: $app-light !important;
  }

  &-dark {
    background-color: $app-dark !important;
  }

  &-white {
    background-color: $app-white !important;
  }

  &-blue {
    background-color: #235eb1 !important;
  }

  &-lt-blue {
    background-color: $app-lt-blue !important;
  }

  &-disabled {
    background-color: $app-disabled-gray !important;
  }

  &-lt-gray {
    background-color: $app-lt-gray !important;
  }

  &-gray {
    background-color: $app-gray !important;
  }

  &-dark-gray {
    background-color: $app-dark-gray !important;
  }
}
