@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";

/* BASE */
@import "./base/buttons.scss";
@import "./base/layouts.scss";
@import "./base/tables.scss";
@import "./base/typography.scss";

/* PAGES */
@import "./pages/warranty-ui.scss";

/* UTILS */
@import "./utils/background.scss";
@import "./utils/border.scss";
@import "./utils/text.scss";

/* THEME */
@import "theme-variables";

body:has(.app-modal) {
  overflow: hidden !important;
}
