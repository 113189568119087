@import "../utils/variables.scss";

.app-btn {
  border: 1px solid transparent !important;

  &-primary {
    background-color: $app-primary !important;
    color: $app-white !important;

    &:hover {
      background-color: darken($app-primary, 5%) !important;
    }
  }

  &-outline-primary {
    background-color: transparent;
    color: $app-primary !important;
    border-color: $app-primary !important;

    &:hover {
      background-color: $app-primary !important;
      color: $app-white !important;
      border-color: transparent !important;
    }
  }

  &-secondary {
    background-color: $app-secondary !important;
    color: $app-white !important;

    &:hover {
      background-color: darken($app-secondary, 5%) !important;
    }
  }

  &-outline-secondary {
    background-color: transparent;
    color: $app-secondary !important;
    border-color: $app-secondary !important;

    &:hover {
      background-color: $app-secondary !important;
      color: $app-white !important;
      border-color: transparent !important;
    }
  }

  &-neutral {
    background-color: $app-neutral !important;
    color: $app-white !important;

    &:hover {
      background-color: darken($app-neutral, 5%) !important;
    }
  }

  &-outline-neutral {
    background-color: transparent;
    color: $app-neutral !important;
    border-color: $app-neutral !important;

    &:hover {
      background-color: $app-neutral !important;
      color: $app-white !important;
      border-color: transparent !important;
    }
  }

  &-success {
    background-color: $app-success !important;
    color: $app-white !important;

    &:hover {
      background-color: darken($app-success, 5%) !important;
    }
  }

  &-outline-success {
    background-color: transparent;
    color: $app-success !important;
    border-color: $app-success !important;

    &:hover {
      background-color: $app-success !important;
      color: $app-white !important;
      border-color: transparent !important;
    }
  }

  &-reverse-success {
    background-color: $app-white !important;
    color: $app-success !important;

    &:hover {
      background-color: darken($app-white, 5%) !important;
    }
  }

  &-danger {
    background-color: $app-danger !important;
    color: $app-white !important;

    &:hover {
      background-color: darken($app-danger, 5%) !important;
    }
  }

  &-outline-danger {
    background-color: transparent;
    color: $app-danger !important;
    border-color: $app-danger !important;

    &:hover {
      background-color: $app-danger !important;
      color: $app-white !important;
      border-color: transparent !important;
    }
  }

  &-info {
    background-color: $app-info !important;
    color: $app-white !important;

    &:hover {
      background-color: darken($app-info, 5%) !important;
    }
  }

  &-outline-info {
    background-color: transparent;
    color: $app-info !important;
    border-color: $app-info !important;

    &:hover {
      background-color: $app-info !important;
      color: $app-white !important;
      border-color: transparent !important;
    }
  }

  &-light {
    background-color: $app-light !important;
    color: $app-dark !important;

    &:hover {
      background-color: darken($app-light, 5%) !important;
    }
  }

  &-outline-light {
    background-color: transparent;
    color: $app-light !important;
    border-color: $app-light !important;

    &:hover {
      background-color: $app-light !important;
      color: $app-dark !important;
      border-color: transparent !important;
    }
  }

  &-dark {
    background-color: $app-dark !important;
    color: $app-light !important;

    &:hover {
      background-color: darken($app-dark, 5%) !important;
    }
  }

  &-outline-dark {
    background-color: transparent;
    color: $app-dark !important;
    border-color: $app-dark !important;

    &:hover {
      background-color: $app-dark !important;
      color: $app-light !important;
      border-color: transparent !important;
    }
  }
}