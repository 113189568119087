@import "./variables.scss";

.app-border {
  &-primary {
    border-color: $app-primary !important;

    &-accent {
      border-color: $app-primary-accent !important;
    }
  }

  &-secondary {
    border-color: $app-secondary !important;
  }

  &-success {
    border-color: $app-success !important;
  }

  &-danger {
    border-color: $app-danger !important;
  }

  &-neutral {
    border-color: $app-neutral !important;
  }

  &-info {
    border-color: $app-info !important;
  }

  &-light {
    border-color: $app-light !important;
  }

  &-dark {
    border-color: $app-dark !important;
  }

  &-gray {
    border-color: $app-gray !important;
  }

  &-white {
    border-color: $app-white !important;
  }

  &-transparent {
    border-color: transparent !important;
  }

  &-danger-standalone {
    border: 5px solid;
    border-color: $app-danger !important;
  }
}
