@import "./sass/main.scss";

#footer {
  height: 39px;
}

#footer-content {
  height: 32px;
  padding: 8px;
  text-align: center;
}

.alert {
  margin: 0 !important;
}

.cdk-drag-preview {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  padding: 10px;
  border: 1px solid #000 !important;
  color: #fff !important;
  background: #3b6c17 !important;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

// Modal Overrides
.finance-vs-cash {
  background: #ff0;

  .app-modal-container {
    .app-modal {
      max-width: 1000px;
      margin: auto;
    }
  }
}

html {
  outline: none !important;
  background: #57873d;
}

.small-green-button {
  font-size: 0.7rem;
  background: #215eb0 !important;
  transition: border, background 0.225s;

  &:hover {
    background: #2e7be1 !important;
    transition: border, background 0.225s;
  }
}

.small-blue-button {
  height: 20px;
  background: #215eb0;
  text-decoration: none;
  cursor: pointer;
  color: #fff;
  font-weight: normal;
  font-size: 0.8rem;
  border-radius: 50rem !important;
  padding: 2px 6px;

  &:hover {
    background: #2e7be1 !important;
    transition: border, background 0.225s;
    text-decoration: none;
    color: #fff;
  }
}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
  background-color: #215eb0 !important;

  &:hover {
    background: #2e7be1 !important;
  }
}
