@import "../utils/variables.scss";

.data-table-container {
  overflow-x: auto;
}

.data-table {
  font-size: 0.8rem;
  word-break: break-word;

  tr {
    border-bottom: 1px solid lightgray;

    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }

  th, td {
    padding: 0.6rem;
    border-right: 1px solid lightgray;

    &:last-child {
      border-right: 1px solid transparent;
    }
  }

  th {
    color: $app-success;
    white-space: nowrap;
  }

  td {
    vertical-align: middle;
  }

  .selectable {
    cursor: pointer;

    &:hover {
      background-color: lighten($app-success, 40%);
    }
  }

  .selected, .selected:hover {
    background-color: $app-success;
    color: $app-white;
  }
}

@media print {

  .data-table th.selected {
    color: $app-success;
  }

}
